import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../shared/services/api.service';
import { IContentLang } from '../../../shared/shared.definitions';

@Injectable({
  providedIn: 'root',
})
export class BaseService implements OnDestroy {
  private readonly destroy$ = new Subject();
  private readonly _formElementsVisible$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly translateService: TranslateService,
    private readonly apiService: ApiService,
    private readonly router: Router,
    private readonly localizeService: LocalizeRouterService
  ) {
    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setLanguages();
      this.setFooterData();
      this.processRouteAndNavigate(this.currentLanguageSource$.getValue().locale);
    });
  }

  private initData: any;

  private readonly languagesSource$ = new BehaviorSubject<Array<IContentLang>>([]);
  languages$ = this.languagesSource$.asObservable();

  public readonly currentLanguageSource$ = new BehaviorSubject({ label: '', locale: '', isPublic: false });
  currentLanguage$ = this.currentLanguageSource$.asObservable();

  private readonly isMobileMenuOpenSource$ = new BehaviorSubject<boolean>(false);
  isMobileMenuOpen$ = this.isMobileMenuOpenSource$.asObservable();

  private readonly footerDataSource$ = new BehaviorSubject({});
  footerData$ = this.footerDataSource$.asObservable();

  private readonly sz2020LogoSource$ = new BehaviorSubject(false);
  sz2020Logo$ = this.sz2020LogoSource$.asObservable();

  fetchInitApi(): void {
    this.apiService.getInit$().subscribe((res) => {
      if (res) {
        this.initData = res.data.languages;
        this.setLanguages();
        this.setFooterData();
      }
    });
  }

  setLanguages(): void {
    const currentLocale = this.translateService.currentLang;
    const languages = this.initData.map((lang: any) => ({ locale: lang.locale, label: lang.label, isPublic: lang.isPublic }));
    const filteredLanguages = languages.filter((lang: any) => {
      return lang.locale !== currentLocale && environment.translation.locales.find((locale) => lang.locale === locale) && lang.isPublic;
    });
    const currentLanguage = languages.find((lang: any) => lang.locale === currentLocale);
    this.languagesSource$.next(filteredLanguages);
    this.currentLanguageSource$.next(currentLanguage);
  }

  setFooterData(): void {
    const currentFooterData = this.initData.find((language: any) => language.locale === this.translateService.currentLang);
    const footerDataWithoutEmail = {
      ...currentFooterData,
      footerContactLead: currentFooterData.footerContactLead.replace(/<a [^>]*href="mailto:[^"]+"[^>]*>.*?<\/a>/g, ''),
    };
    this.footerDataSource$.next(footerDataWithoutEmail);
  }

  isMobileMenuOpen(): boolean {
    return this.isMobileMenuOpenSource$.value;
  }

  toggleMobileMenu(val?: boolean): void {
    typeof val !== 'undefined' && val !== null
      ? this.isMobileMenuOpenSource$.next(val)
      : this.isMobileMenuOpenSource$.next(!this.isMobileMenuOpenSource$.value);
  }

  fetchTranslations(): void {
    this.apiService.getAllLangTranslations$().subscribe((res) => {
      const translations: any = Object.entries(res.data).reduce((acc: any, item: Array<any>) => {
        if (item[1]?.data?.length > 0) {
          const translation: any = {};
          for (const translationObj of item[1].data) {
            translation[translationObj.key] = translationObj.value;
          }
          acc[item[0]] = translation;
        }
        return acc;
      }, {});
      Object.keys(translations).forEach((item) => {
        this.translateService.setTranslation(item, translations[item], true);
      });
    });
  }

  subscribeToLocalizeEvents(): void {
    this.localizeService.routerEvents.pipe(takeUntil(this.destroy$)).subscribe((language: string) => {
      this.processRouteAndNavigate(language);
    });
  }

  private processRouteAndNavigate(language: string): void {
    const splitUrl = this.router.url.split('/');

    const previousLocaleCode =
      splitUrl[1]?.length === 2 && environment.translation.locales.includes(splitUrl[1]) ? splitUrl[1] : this.translateService.defaultLang;
    const urlWithoutLocale = splitUrl?.filter((segment) => !environment.translation.locales.includes(segment));
    const previousTranslations = this.translateService.store.translations[previousLocaleCode];
    const newTranslations = this.translateService.store.translations[language];
    const newRoute: Array<string> = urlWithoutLocale.reduce((acc: Array<string>, segment: string) => {
      if (segment && segment !== previousLocaleCode) {
        Object.entries(previousTranslations).find((item) => {
          if (item[1] === segment && item[0].includes('ROUTES.')) {
            const newValue = Object.entries(newTranslations).find((trans) => trans[0] === item[0])?.[1];
            acc.push(newValue as string);
          }
        });
      }

      return acc;
    }, []);

    const filteredUrl = splitUrl.filter((segment) => segment !== '' && segment !== previousLocaleCode);

    if (filteredUrl.length === newRoute.length) {
      if (language !== this.translateService.defaultLang) {
        newRoute.unshift(language);
      }
      newRoute.unshift('');

      this.router.navigate(newRoute).catch((err) => {
        return throwError(err);
      });
    } else {
      this.router.navigate(['', language]);
    }
  }

  isSz2020LogoActive(): boolean {
    return this.sz2020LogoSource$.value;
  }

  setSz2020Logo(val: boolean): void {
    this.sz2020LogoSource$.next(val);
  }

  get formElementsVisible$(): Observable<boolean> {
    return this._formElementsVisible$.asObservable();
  }

  setFormElementsVisibility(visible: boolean): void {
    this._formElementsVisible$.next(visible);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
